@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";
@import "../../Components/CommonForBoth/rightbar.scss";

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

svg > rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-board {
  display: inherit !important;

  .react-kanban-column {
    background-color: $white;
    padding: 1.25rem;

    [data-rbd-draggable-context-id] {
      > div {
        display: block !important;
      }
    }
  }

  > div {
    display: flex;
    justify-content: center;
    gap: 25px;

    @media (max-width: 991.98px) {
      flex-direction: column;

      .react-kanban-column {
        width: 100%;
      }
    }
  }
}

.react-kanban-column {
  width: 33.33%;
}

.react-kanban-column > div {
  display: block !important;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -4px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

//react-table
.custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }

    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
}

.editor_email .ck-content {
  height: 200px;
}

// .my-masonry-grid {
//   display: -webkit-box;
//   /* Not needed if autoprefixing */
//   display: -ms-flexbox;
//   /* Not needed if autoprefixing */
//   display: flex;
//   margin-left: -25px;
//   /* gutter size offset */
//   width: auto;
// }

// .my-masonry-grid_column {
//   padding-left: 25px;
//   /* gutter size */
//   background-clip: padding-box;
// }

// /* Style your items */
// .my-masonry-grid_column>div {
//   /* change div to reference your elements you put in <Masonry> */
//   background: transparent;
//   margin-bottom: 20px;
// }

.jobs-categories .card-body a:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 991.98px) {
  .search-box {
    display: block !important;
    .search-label {
      float: none;
      width: 100%;
      .form-control {
        border-radius: 0.25rem !important;
      }
    }
  }
}

// select

.css-1s2u09g-control, .css-1pahdxg-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.css-26l3qy-menu {
  box-shadow: none !important;
  border: 1px solid $input-border-color !important;
}

.css-4ljt47-MenuList {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.css-319lph-ValueContainer .css-qc6sy-singleValue {
  color: $input-color !important;
}
.select2-selection .css-b62m3t-container {
  color: $input-color !important;
}
.css-1n7v3ny-option, .css-9gakcf-option {
  background-color: $primary !important;
  color: $white !important;
}


// rdw-editor

.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: $input-border-color !important;
  background-color: var(--#{$prefix}custom-white) !important;
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: none !important;
}

.rdw-dropdown-selectedtext {
  color: var(--#{$prefix}body-color) !important;
}

[data-layout-mode="dark"] {
  .rdw-option-wrapper,
  .rdw-dropdown-selectedtext {
    img {
      filter: invert(1) !important;
    }
  }
}

// ck editor

.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
  height: 300px !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}

.selectall.checkbox-wrapper-mail label:before{
  margin-top: 0;
}

// scroll
.ps__rail-y, .ps__rail-x{
  z-index: 999 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--#{$prefix}custom-white) inset !important;
    background-color: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
    -webkit-text-fill-color: var(--#{$prefix}body-color) !important;
} 



// Select2

.select2-selection__control, .select__control, .select2-selection .css-13cymwt-control, .css-t3ipsp-control{
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.select2-selection__menu, .select__menu, .css-1nmdiq5-menu{
  background-color: var(--#{$prefix}card-bg) !important;
  box-shadow: 0 0 0 1px var(--#{$prefix}border-color) , 0 4px 11px var(--#{$prefix}border-color)  !important;
}

.select2-selection__option--is-focused, .select__option--is-focused,  .css-d7l1ni-option{
  background-color: $primary !important;
  color: $white !important;
}

.select__multi-value, .select2-selection__multi-value, .css-1p3m7a8-multiValue{
  background-color: var(--#{$prefix}light) !important;
}

.select__multi-value__label, .select2-selection__multi-value__label, .css-qbdosj-Input, .css-1dimb5e-singleValue, .css-wsp0cs-MultiValueGeneric{
  color: var(--#{$prefix}body-color) !important;
}

.select__multi-value__remove, .select2-selection__multi-value__remove{
  background-color: var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

// auth-logo

[data-layout-mode="dark"]{
  .auth-logo{
    .auth-logo-light{
      display: block;
    }
    .auth-logo-dark{
      display: none;
    }
  }
}

//login screen
@media (min-width: 0px) {
  .login-brand {
    font-size: 12px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }

  .certi-template {
    display: none;
  }

  .body-public {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: $primary;
    animation: changeBackgroundColor 2500ms linear forwards;
  }

  @keyframes changeBackgroundColor {
    0%{
      background: $primary;
    }
    99%{
      background: $primary;
    }
    100%{
      background: linear-gradient(black, $primary 50%);
    }
  }

  .cert-dtlColPublic {
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    gap:16px;
    animation: postloadDelay 2500ms linear forwards;
  }

  @keyframes postloadDelay {
    0%{
     opacity: 0;
    }
    99%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  .edit-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }

  .prog-bar{
    padding-left: 40px;
    padding-right: 40px;
  }

  .wave {
    background: url(../svg/bottomWave.svg) repeat-x;
    position: absolute;
    width: 8000px;
    bottom: 0px;
    left: 0px;
    height: 400px;
    animation: wave 16s linear infinite;
  }

  .wave2 {
    background: url(../svg/bottomWave2nd.svg) repeat-x;
    position: absolute;
    width: 8000px;
    bottom: 0px;
    left: 0px;
    height: 200px;
    animation: wave 12s linear infinite;
  }

  @keyframes wave {
    0% { left: 0; }
    100% { left: -2000px;}
  }

  .box {
    position: fixed;
    top: 0;
    transform: rotate(80deg); 
    left: 0;
  }

  .waveSec {
    z-index: 0;
    position: absolute;
    opacity: .4;
    width: 600px;
    height: 360px;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 43%;
  }

  @keyframes rotate {
    from {transform: rotate(0deg);}
    from {transform: rotate(360deg);}
  }

  .waveSec.wave3 {
    animation: rotate 16000ms infinite linear;
    opacity: 5%;
    background: white;
  }

  .waveSec.wave4 {
    animation: rotate 12000ms infinite linear;
    opacity: 10%;
    background: white;
  }

  .cert-textCol {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  .cert-text1 {
    color: $primary;
    font-size: 16px;
  }

  .cert-text2 {
    font-size: 24px;
    color: $primary;
    font-weight: bold;
  }

  .cert-display {
    height: 180px;
    background-size: cover;
    width: auto;
    position: relative;
    align-self: center;
  }

  .hiddenAnim {
    opacity: 0;
    filter: blur(4px);
    transform: translateX(-100%);
    transition: 1000ms ease-out;
  }

  .enterAnim {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }

  .hiddenAnimVertical {
    opacity: 0;
    filter: blur(4px);
    transform: translateY(100%);
    transition: 1000ms ease-out;
  }

  .hiddenAnimVertical.enterAnim {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }

  .cert-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cert-CTA {
    display: none;
  }

  .cert-item {
    margin-top: 16px;
    position: relative;
    display: flex;
    width: 132px;
    height: 160px;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    // box-shadow: 2px 2px 8px $secondary;
    transition: 200ms ease-in-out;
    // border: 1px solid $primary;
  }

  .cert-item:hover {
   background-color: $primary;
   color: white;
   cursor: pointer;
  }

  .cert-item:hover .cert-item-img {
    transform: scale(1.1);
  }

  .cert-item-imgCtn {
    height: 80px;
    width: auto;
    position: relative;
    align-self: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cert-item-img {
    height: 80px;
    background-size: cover;
    width: auto;
    position: relative;
    align-self: center;
    transition: 200ms ease-in-out;
  }

  .cert-dtlCol {
    position: relative;
    display: flex;
    z-index: 50;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap:16px;
  }

  .public-postloadingScreen {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: loadingDisappearance 2500ms ease-out forwards;
  }

  .mountainRange1 {
    background: url(../svg/mountainRange1.svg) repeat-x;
    position: absolute;
    width: 8000px;
    bottom: 0px;
    left: -200px;
    height: 120px;
    animation: postloadDelay 2500ms linear forwards;
  }

  .mountainRange2 {
    background: url(../svg/mountainRange2.svg) repeat-x;
    position: absolute;
    width: 8000px;
    bottom: 0px;
    left: -400px;
    height: 480px;
    animation: postloadDelay 2500ms linear forwards;
  }

  .certName-public {
    font-size: 32px;
    font-weight: bold;
  }

  .blurScreen {
    z-index: 99;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    animation: blurAppear 5000ms forwards;
  }

  @keyframes blurAppear {
    0%{
      opacity: 0;
    }
    30%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    70%{
      opacity: 0;
      display: none;
      transform: translateY(0);
    }
    71%{
      opacity: 0;
      transform: translateY(-100%);
    }
    100%{
      opacity: 0;
      display: none;
      transform: translateY(-100%);
    }
  }

  @keyframes loadingDisappearance {
    0% {
      opacity: 100%;
    }
    99% {
      opacity: 100%;
    }
    100% {
      opacity: 0%;
    }
  }

  .loading-text {
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 100%;
    font-size: 12px;
    letter-spacing: 12px;
    text-align: center;
  }

  .loading-text-postload {
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 100%;
    font-size: 12px;
    letter-spacing: 12px;
    text-align: center;
    animation: textFallback 2500ms ease-in-out forwards;
  }

  @keyframes textFallback {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(-8px, -8px);
    }
    40% {
      transform: translate(-16px, -16px);
    }
    100% {
      transform: translate(-20000px, -20000px);
    }
  }

  .shooting-star {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;

    transform: rotate(225deg);
    box-shadow: 0 0 0 4px rgba(255,255,255,0.1), 0 0 0 8px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);animation: glowing 1400ms ease-out infinite;
  }

  @keyframes glowing {
    0% {
      box-shadow: 0 0 0 0px rgba(255,255,255,0.1), 0 0 0 0px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
    }
    20% {
      box-shadow: 0 0 0 0px rgba(255,255,255,0.1), 0 0 0 8px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
    }
    40% {
      box-shadow: 0 0 0 8px rgba(255,255,255,0.1), 0 0 0 8px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
    }
    100% {
      box-shadow: 0 0 0px 8px rgba(255,255,255,0), 0 0 0px 8px rgba(255,255,255,0), 0 0 20px rgba(255,255,255,1);
    }
    
  }

  .shooting-star::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    width: 200px;
    background: linear-gradient(90deg,white,transparent);
    animation: trailglow 2000ms linear infinite;
  }

  .four-pointed-star{
    position:absolute;
    width: 16px;
    height: 16px;
    animation: shinyAppearance 2500ms ease-in-out forwards;
  }

  .four-pointed-star:before{
    content:"";
    position:absolute;
    background:white;
    width: 16px;
    height: 20px;
    transform:rotate(-45deg) skewX(22.5deg) skewY(22.5deg);
    box-shadow: 0 0 0 0px rgba(255,255,255,0.1), 0 0 0 0px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
  }

  .four-pointed-star:after{
    content:"";
    position:absolute;
    background:white;
    width: 16px;
    height: 20px;
    transform:rotate(45deg) skewX(22.5deg) skewY(22.5deg);
    box-shadow: 0 0 0 0px rgba(255,255,255,0.1), 0 0 0 0px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
  }

  .shiny-four-pointed-star{
    z-index: 99;
    position:absolute;
    width: 16px;
    height: 16px;
    top: 0px;
    right: 64px;
    animation: shiny 4000ms ease-in-out infinite;
  }

  .shiny-four-pointed-star:before{
    content:"";
    position:absolute;
    background:white;
    width: 16px;
    height: 20px;
    transform:rotate(-45deg) skewX(22.5deg) skewY(22.5deg);
    box-shadow: 0 0 0 0px rgba(255,255,255,0.1), 0 0 0 0px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
  }

  .shiny-four-pointed-star:after{
    content:"";
    position:absolute;
    background:white;
    width: 16px;
    height: 20px;
    transform:rotate(45deg) skewX(22.5deg) skewY(22.5deg);
    box-shadow: 0 0 0 0px rgba(255,255,255,0.1), 0 0 0 0px rgba(255,255,255,0.1), 0 0 20px rgba(255,255,255,1);
  }

  @keyframes shinyAppearance {
    0%{
      transform: scale(0) rotate(-90deg);
    }
    20%{
      transform: scale(0) rotate(-90deg);
    }
    30%{
      transform: scale(1) rotate(0deg);
    }

    100%{
      transform: scale(5) rotate(180deg);
    }
  }

  @keyframes shiny {
    0%{
      opacity: 0;
    }
    84%{
      opacity: 0;
    }
    85%{
      opacity: 1;
      transform: scale(0) rotate(-90deg);
    }
    95%{
      opacity: 1;
    }
    100%{
      transform: scale(2) rotate(90deg);
      opacity: 0;
    }
  }

  .trail:nth-child(1) {
    position: absolute;
    height: 0.4px;
    width: 200px;
    transform: rotate(225deg);
    background: white;
    animation: trailAnimation 1000ms linear infinite;
    top: 0px;
    left: 600px;
  }

  .trail:nth-child(2) {
    position: absolute;
    height: 0.4px;
    width: 200px;
    transform: rotate(225deg);
    background: white;
    animation: trailAnimation 1000ms linear infinite;
    top: 100px;
    left: 600px;
  }

  .trail:nth-child(3) {
    position: absolute;
    height: 0.4px;
    width: 200px;
    transform: rotate(225deg);
    background: white;
    animation: trailAnimation 1000ms linear infinite;
    top: 400px;
    left: 200px;
  }

  .trail:nth-child(4) {
    position: absolute;
    height: 0.4px;
    width: 200px;
    transform: rotate(225deg);
    background: white;
    animation: trailAnimation 1000ms linear infinite;
    top: 300px;
    left: -300px;
  }

  .trail:nth-child(5) {
    position: absolute;
    height: 0.4px;
    width: 200px;
    transform: rotate(225deg);
    background: white;
    animation: trailAnimation 1000ms linear infinite;
    top: 300px;
    left: -40px;
  }

  .trail:nth-child(6) {
    position: absolute;
    height: 0.4px;
    width: 200px;
    transform: rotate(225deg);
    background: white;
    animation: trailAnimation 1000ms linear infinite;
    top: 300px;
    left: 500px;
  }

  @keyframes trailAnimation {
    0% {
      transform: rotate(225deg) translateX(0px);
    }
    100% {
      transform: rotate(225deg) translateX(2000px);
    }
  }

  @keyframes trailglow {
    0% {
      width: 180px;
      box-shadow: 0 0 20px rgba(255,255,255,0.1);
    }
    25% {
      box-shadow: 0 0 20px rgba(255,255,255,1);
    }
    50% {
      width: 200px;
      box-shadow: 0 0 20px rgba(255,255,255,1);
    }
    75% {
      box-shadow: 0 0 20px rgba(255,255,255,1);
    }
    100% {
      width: 180px;
      box-shadow: 0 0 20px rgba(255,255,255,0.1);
    }
  }
}

@media (min-width: 496px) {
  .login-brand {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .prog-bar{
    padding-left: 80px;
    padding-right: 80px;
  }

  .cert-display {
    height: 212px;
  }

  .cert-item {
    width: 160px;
    height: 200px;
    padding: 8px;
  }

  .cert-item-imgCtn {
    height: 96px;
  }

  .cert-item-img {
    height: 96px;
  }

  .shiny-four-pointed-star{
    right: 96px;
  }
}

@media (min-width: 768px) {
  .login-brand {
    margin-top: 12px;
    font-size: 14px;
  }

  .certi-template {
    display: flex;
  }

  .edit-screen {
    flex-direction: row;
    gap: 0rem;
  }

  .prog-bar{
    padding-left: 160px;
    padding-right: 160px;
  }

  .cert-main {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding: 40px 24px;
  }

  .cert-display {
    height: 280px;
  }

  .cert-textCol {
    align-self: center;
    align-items: start;
  }

  .cert-text1 {
    font-size: 16px;
    // text-shadow: 2px 2px $primary;

  }

  .cert-text2 {
    font-size: 32px;
    // text-shadow: 2px 2px $primary;
  }

  .cert-CTA {
    display: flex;
    margin-top: 48px;
  }

  .cert-item {
    width: 240px;
    height: 240px;
    padding: 20px;
    border-radius: 0.25rem;
  }

  .cert-item-imgCtn {
    height: 132px;
  }

  .cert-item-img {
    height: 132px;
  }

  .shiny-four-pointed-star{
    right: 240px;
  }
}


@media (min-width: 1024px) {
  .cert-text1 {
    font-size: 20px;
  }

  .cert-text2 {
    font-size: 36px;
  }

  .cert-item {
    width: 280px;
    height: 256px;
  }

  .cert-item-imgCtn {
    height: 160px;
  }

  .cert-item-img {
    height: 160px;
  }

  .shiny-four-pointed-star{
    right: 360px;
  }

  .loading-text {
    font-size: 16px;
  }

  .loading-text-postload {
    font-size: 16px;
  }
}

@media (min-width: 1280px) {

  .login-brand {
    font-size: 16px;
    margin-top: 16px;
  }

  .prog-bar{
    padding-left: 200px;
    padding-right: 200px;
  }

  .waveSec {
    width: 1000px;
    height: 800px;
  }

  .shiny-four-pointed-star{
    right: 480px;
  }
}

@media (min-width: 1536px) {
  .waveSec {
    width: 1200px;
    height: 960px;
  }

  .shiny-four-pointed-star{
    right: 560px;
  }

  .loading-text {
    font-size: 20px;
  }

  .loading-text-postload {
    font-size: 20px;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.choose-unit {
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.choose-unit:hover {
  background-color: $primary;
}

.choose-unit:hover .choose-unit-text {
  color: white !important;
}

.editSpace {
  position: relative;
  padding: 0rem 0rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  border: 2px solid $secondary;
  border-radius: 8px;
  background-color: $primary;
}

.add-placeholder {
  color: $primary;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  border: 0px solid white;
}

.add-placeholder:hover {
  background-color: $primary;
  color: white;
  transition: 200ms ease-in-out;
}

.editBar {
  width: 100%;
  height: 4rem;
  background-color: white;
  border: 2px solid $primary;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
}

.custom-bar {
  height: 3rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  padding: 0rem 1rem;
}

.template-select {
  position: relative;
  display: flex;
  padding: 0rem 1rem;
  flex-direction: row;
  justify-items: start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.template-choice {
  position: relative;
  max-height: 8rem;
}

.editSpace-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

.item-placeholder {
  background-color: $primary;
  color: white;
  position: absolute;
  border-radius: 4px;
  border: 2px solid $primary;
  padding: 2px;
  display: inline-block;
  cursor: pointer;
  transform: translateX(-50%) translateY(-50%);
}

.preview-placeholder {
  color: $primary;
  position: absolute;
  padding: 2px;
  display: inline-block;
  cursor: pointer;
  transform: translateX(-50%) translateY(-50%);
}

.prog-step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 16px;
}

.prog-text {
  text-align: center;
}

.hover-pointer :hover {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary; 
}

.scrollbar-transparent {

  &::-webkit-scrollbar-track {
    background: transparent; // Makes the scrollbar track transparent
  }

  &::-webkit-scrollbar-thumb {
    background: #A3ACC2; // Style for the scrollbar thumb
    border-radius: 4px; // Roundness of the scrollbar thumb
  }
}
