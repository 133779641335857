//
// Google font - Poppins
//

// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400..800&display=swap');

// Certificate

// @font-face {
//   font-family: 'Baskerville';
//   src: url(../../../fonts/certificate-fonts/Baskerville.ttf) format('truetype');
// }

@font-face {
  font-family: 'Baskerville';
  src: url(../../../fonts/certificate-fonts/BaskervilleBT.woff) format('woff');
}

@font-face {
  font-family: 'Baskerville';
  font-style: italic;
  src: url(../../../fonts/certificate-fonts/BaskervilleItalicBT.woff) format('woff');
}

@font-face {
  font-family: 'Baskerville';
  font-weight: bold;
  src: url(../../../fonts/certificate-fonts/BaskervilleBoldBT.woff) format('woff');
}

@font-face {
  font-family: 'Baskerville';
  font-style: italic;
  font-weight: bold;
  src: url(../../../fonts/certificate-fonts/BaskervilleBoldItalicBT.woff) format('woff');
}


@font-face {
  font-family: 'Myriad Pro';
  font-weight: bold;
  src: url(../../../fonts/certificate-fonts/MYRIADPRO-BOLD.OTF) format('opentype');
}

@font-face {
  font-family: 'Myriad Pro';
  font-style: italic;
  font-weight: bold;
  src: url(../../../fonts/certificate-fonts/MYRIADPRO-BOLDIT.OTF) format('opentype');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url(../../../fonts/certificate-fonts/MyriadPro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto_Serif';
  src: url(../../../fonts/certificate-fonts/Noto_Serif.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  src: url(../../../fonts/certificate-fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'UTM Times';
  src: url(../../../fonts/certificate-fonts/UTM_Times.ttf) format('truetype');
}

@font-face {
  font-family: 'UTM Times';
  src: url(../../../fonts/certificate-fonts/UTM_TimesItalic.ttf) format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'UTM Times';
  src: url(../../../fonts/certificate-fonts/UTM_TimesBold_Italic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'UTM Times';
  src: url(../../../fonts/certificate-fonts/UTM_TimesBold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'UTM Zirkon';
  src: url(../../../fonts/certificate-fonts/UTM_Zirkon.ttf) format('truetype');
}

@font-face {
  font-family: 'DFVN ED Drayton';
  src: url(../../../fonts/certificate-fonts/DFVN_ED_Drayton.otf) format('opentype');
}